import React from "react";
import Form from "./contact/Form";

export default function Contact({ token }) {
	return (
		<div>
			<div className="content">
				<div className="inner">
					<div className="row">
						<div className="col-6">
							<h2>
								<span>Contact</span> Me.
							</h2>
							<p>
								You can send me a direct message or you can reach out using the
								information below. Looking forward to speaking with you!
							</p>
							<h3>Email</h3>
							<a href="mailto:eric@ericoliver.io">eric@ericoliver.io</a>
							<br />
							<br />
							<h3>Phone</h3>
							<a href="tel:228-219-9313">228-219-9313</a>
							<br />
							<br />
							<h3>LinkedIn</h3>
							<a
								href="https://www.linkedin.com/in/ericmarkoliver/"
								target="_blank"
								rel="noopener noreferrer">
								/in/ericmarkoliver/
							</a>
						</div>
						<div className="col-6">
							<Form token={token} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
