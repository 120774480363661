import React, { useEffect, useRef } from "react";

function MatrixCanvas({ height }) {
	const canvasRef = useRef(null); // Create a reference to the canvas element

	useEffect(() => {
		const canvas = canvasRef.current; // Get the canvas element
		const ctx = canvas.getContext("2d");
		let columns; // Number of columns
		let drops; // An array of drops

		function init() {
			canvas.width = window.innerWidth;
			canvas.height = height || window.innerHeight;
			const fontSize = 20;
			columns = canvas.width / fontSize;
			drops = [];
			for (let i = 0; i < columns; i++) {
				drops[i] = Math.floor(Math.random() * canvas.height); // Random y position
			}
		}

		function draw() {
			ctx.fillStyle = "rgba(0, 0, 0, 0.05)"; // Transparent black
			ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with the transparent black
			ctx.fillStyle = "#00a2ff"; // Blue
			ctx.font = "14px monospace";

			for (let i = 0; i < drops.length; i++) {
				const text = String.fromCharCode(Math.random() * 128); // Random character
				ctx.fillText(text, i * 20, drops[i] * 20); // Draw the character
				drops[i]++; // Move the character down
				
				// If the character is at the bottom, reset it to the top
				if (drops[i] * 20 > canvas.height && Math.random() > 0.95) {
					drops[i] = 0;
				}
			}
		}

		init();

		const intervalId = setInterval(draw, 33); // Draw every 33 milliseconds

		window.addEventListener("resize", init); // Run this effect whenever the height or window width changes

		return () => {
			clearInterval(intervalId); // Clean up the interval
			window.removeEventListener("resize", init); // Remove the resize listener
		};
	}, [height]);

	return <canvas ref={canvasRef}></canvas>; // Render the canvas
}

export default MatrixCanvas;
