import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Skills from "./Skills";

function About() {
	AOS.init();
	return (
		<div>
			<div className="content">
				<div className="inner">
					<div className="row">
						<div className="col-6">
							<h2>
								<span>About</span> Me.
							</h2>
							<p>
								I am a software engineer experienced in <span>frontend</span>{" "}
								and <span>backend</span> development. I have worn many hats in
								my career, including developer, team lead, and director.
							</p>
							<p>
								I have a passion for learning and teaching others. I am a
								self-starter and a problem solver. I am always looking for ways
								to improve my skills and the skills of those around me. Check
								out my{" "}
								<a
									href="https://www.youtube.com/@DevDrawer"
									target="_blank"
									rel="noopener noreferrer">
									YouTube channel
								</a>{" "}
								to see how I help other developers from around the world.
							</p>
						</div>
						<div className="col-6">
							<div className="aboutMeStats">
								<div className="row">
									<div className="col-3">
										<strong>Experience</strong>
									</div>
									<div className="col-9">
										Over 18 years <em>(9 as director or senior)</em>
									</div>
								</div>
								<div className="row">
									<div className="col-3">
										<strong>Languages</strong>
									</div>
									<div className="col-9">
										PHP, JavaScript, MySQL / MSSQL, React.js, Node.js, Sass,
										HTML, VB.NET, Bash
									</div>
								</div>
								<div className="row">
									<div className="col-3">
										<strong>Location</strong>
									</div>
									<div className="col-9">Atlanta, GA</div>
								</div>
								<div className="row">
									<div className="col-3">
										<strong>Email</strong>
									</div>
									<div className="col-9">
										<a href="mailto:eric@ericoliver.io">eric@ericoliver.io</a>
									</div>
								</div>
								<div className="row">
									<div className="col-3">
										<strong>Phone</strong>
									</div>
									<div className="col-9">
										<a href="tel:228-219-9313">228-219-9313</a>
									</div>
								</div>
								<div className="row">
									<div className="col-3">
										<strong>Referrals</strong>
									</div>
									<div className="col-9">
										<a
											href="https://www.linkedin.com/in/ericmarkoliver/details/recommendations/"
											target="_blank"
											rel="noopener noreferrer">
											View on LinkedIn
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
					<br />
					<h2 className="h2Small text-center">Skillset</h2>
					<p className="text-center">
						<em>This website is built using PHP, React and Sass</em>
					</p>
					<br />
					<div className="row">
						<div className="col-5">
							<div className="text-center">
								<div className="skills" data-aos="fade-right">
									<Skills />
								</div>
							</div>
						</div>
						<div className="col-7">
							<div className="row">
								<div className="col-12">
									<div className="card" data-aos="fade-up" data-aos-delay="100">
										<div className="card-body">
											<h3 className="text-center">Backend</h3>
											<p className="text-center">
												<strong>PHP, Node.js, VB.NET, MySQl, MSSQL</strong>
											</p>
											<p>
												In PHP, I've engineered integrations, mentored
												developers, and created WordPress plugins. Proficiency
												in VB led to successful Windows solutions, enhancing
												brands and client satisfaction. Expertise in MSSQL and
												MySQL ensured efficient data management, reducing system
												downtime.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="card" data-aos="fade-up" data-aos-delay="100">
										<div className="card-body">
											<h3 className="text-center">Frontend</h3>
											<p className="text-center">
												<strong>
													JavaScript, jQuery, React.js, Sass, HTML, Photoshop +
													Illustrator
												</strong>
											</p>
											<p>
												With JavaScript, I've built interactive and dynamic user
												interfaces, leveraging the power of React to create
												scalable and feature-rich applications. Sass and HTML
												were instrumental in crafting visually appealing and
												responsive layouts.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="card" data-aos="fade-up" data-aos-delay="200">
										<div className="card-body">
											<h3 className="text-center">Management</h3>
											<p className="text-center">
												<strong>
													Agile, Scrum, Jira, Project Management, Team
													Leadership
												</strong>
											</p>
											<p>
												As a director, I've led teams, managed projects, and set
												a vision for the future. I've implemented Agile and
												Scrum methodologies, improving efficiency and
												collaboration. My leadership has fostered a culture of
												innovation and continuous improvement.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="card" data-aos="fade-up" data-aos-delay="200">
										<div className="card-body">
											<h3 className="text-center">Marketing</h3>
											<p className="text-center">
												<strong>SEO, SEM, Email Marketing, Content</strong>
											</p>
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="card" data-aos="fade-up" data-aos-delay="300">
										<div className="card-body">
											<h3 className="text-center">Server</h3>
											<p className="text-center">
												<strong>Docker, Apache, SSH, CLI, Bash</strong>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="row" data-aos="fade-up">
						<div className="col-12 text-center">
							<p>
								Overall, I've driven growth, improved client satisfaction, and
								delivered quality solutions across industries and software
								languages.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;
