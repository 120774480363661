import React, { useState } from "react";
import {
	GoogleReCaptchaProvider,
	GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Header from "./components/Header";
import Main from "./components/Main";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function App() {
	const [token, setToken] = useState("");

	const handleTokenVerification = (newToken) => {
		if (!token) {
			setToken(newToken);
		}
	};
	return (
		<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
			<div className="App">
				<Header />
				<Main token={token} />
				<Sidebar />
				<Footer />
				<GoogleReCaptcha
					className="google-recaptcha"
					onVerify={handleTokenVerification}
				/>
			</div>
		</GoogleReCaptchaProvider>
	);
}

export default App;
