import React from "react";

export default function Skills() {
	return (
		<div>
            <h4>Hard Skills</h4>
            <ul>
                <li>
                    Expertise in PHP, VB.NET, and JavaScript (vanilla, jQuery, and
                    Node.js).
                </li>
                <li>Expertise in database management with MySQL and MSSQL.</li>
                <li>Expertise in Restful APIs, JSON, and documentation.</li>
                <li>Expertise in WordPress. methodology and architecture.</li>
                <li>
                    Experienced in software testing and debugging, including PHP
                    Unit.
                </li>
                <li>Experienced in LAMP (GUI/CLI) architecture.</li>
                <li>
                    Experienced in web application frameworks such as React and
                    Angular.
                </li>
            </ul>
            <h4>Soft Skills</h4>
            <ul>
                <li>
                    Expertise in project management and resource allocation.
                </li>
                <li>
                    Expertise in scope and budget for project and company needs.
                </li>
                <li>Expertise in timeline and schedule for self and team.</li>
                <li>Expertise with team leadership and mentoring.</li>
                <li>Proficient with Agile and Scrum methodologies.</li>
            </ul>
        </div>
	);
}
