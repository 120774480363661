import React from "react";

/**
 * The links array contains the links for the contact methods.
 * href: The link to the contact method.
 * icon: The icon class for the contact method.
 * target: The target attribute for the link.
 * rel: The rel attribute for the link.
 * delay: The delay for the AOS animation.
 */
const links = [
	{
		href: "tel:228-219-9313",
		icon: "bi-telephone-forward",
		delay: 100,
	},
	{
		href: "mailto:eric@ericoliver.io",
		icon: "bi-envelope-at",
		delay: 200,
	},
	{
		href: "https://www.linkedin.com/in/ericmarkoliver/",
		icon: "bi-linkedin",
		target: "_blank",
		rel: "noopener noreferrer",
		delay: 300,
	},
	{
		href: "https://github.com/thedevdrawer",
		icon: "bi-github",
		target: "_blank",
		rel: "noopener noreferrer",
		delay: 400,
	},
	{
		href: "https://www.youtube.com/@DevDrawer",
		icon: "bi-youtube",
		target: "_blank",
		rel: "noopener noreferrer",
		delay: 500,
	},
];

export default function Links() {
	return (
		<div>
			<div className="contactMethod text-center">
				{links.map((link, index) => (
					<a
						key={index}
						href={link.href}
						target={link.target || ""}
						rel={link.rel || ""}
						data-aos="fade-up"
						data-aos-delay={link.delay}>
						<i className={`bi ${link.icon}`}></i>
					</a>
				))}
			</div>
		</div>
	);
}
