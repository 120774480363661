import React from "react";
import preview from "../../../assets/portfolio/dealerinspire/preview.webp";

export default function DealerInspire() {
	return (
		<div className="row project">
			<div className="col-5" data-aos="fade-up">
				<h2>Dealer Inspire </h2>
				<h3>Integrating GA4 for 6000+ websites</h3>
				<p>
					Dealer Inspire needed to upgrade all of it's client portfolio from
					Google Universal Analytics to the newly released GA4. I built an
					integration that helped them accomplish this and increase client
					tracking from 65% to 95% for all client websites.
				</p>
				<p>
					<em>Skills Used: PHP, JavaScript, Typescript, Google Analytics</em>
				</p>
			</div>
			<div className="col-7 image">
				<img
					src={preview}
					alt="Dealer Inspire GA4 Tracking"
					data-aos="fade-up"
					data-aos-delay="300"
				/>
			</div>
		</div>
	);
}
