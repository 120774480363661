import React from "react";
import MatrixCanvas from "../MatrixCanvas";

function Home() {
	return (
		<div>
			<MatrixCanvas />
			<div className="content">
				<div className="inner">
					<div className="row">
						<div className="col-12">
							<h1>
								Hello. I'm <span>Eric Oliver</span>.
							</h1>
							<h2>A Software Engineer</h2>
							<p className="activelyLooking">
								I am <u>actively looking</u> for a position as a Lead, Senior,
								or Manager level <span>Software Engineer</span> or <span>Web Developer</span>.
								<br />
								If you would like to <span>hire me</span>, click the buttons
								below.
							</p>
							<br />
							<br />
							<p>
								<a href="#resume" className="btn draw-border">
									My Resum&eacute;
								</a>
								<a href="#contact" className="btn draw-border">
									Reach Out
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
