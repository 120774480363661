import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";

// Created the projects array
const projects = [
	{ name: "DealerInspire", component: "DealerInspire" },
	{ name: "Netflix", component: "Netflix" },
	{ name: "ScarletPearl", component: "ScarletPearl" },
	{ name: "Diberville", component: "Diberville" },
	{ name: "Crabman", component: "Crabman" },
	{ name: "Palace", component: "Palace" },
];

// Dynamically import the mobile component
const mobileProjects = projects.map((project) => ({
	...project,
	component: `mobile/${project.component}`,
}));

// Dynamically import the component
const PortfolioItem = ({ componentName }) => {
	const Component = require(`./portfolio/${componentName}`).default;
	return <Component />;
};

export default function Portfolio() {
	const [isMobile, setIsMobile] = useState(false);

	// Check if the window is resized
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1024);
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const renderProjects = isMobile ? mobileProjects : projects; // Render the mobile projects if the screen is less than 1024px

	return (
		<div>
			<div className="content">
				<div className="inner">
					<h2>
						My <span>Portfolio</span>.
					</h2>
					<br />
					<div className="row">
						<div className="col-6">
							<h3>
								See how I brought value to my employer and client products.
							</h3>
						</div>
						<div className="col-6">
							<p>
								I have worked with companies around the world and have been
								praised by clients and employers. Throughout my career, I have
								won several awards and have been recognized for my work locally,
								regionally, and nationally.
							</p>
						</div>
					</div>
					<div className={isMobile ? "projectsMobile" : "projects"}>
						{renderProjects.map((project, index) => (
							<PortfolioItem key={index} componentName={project.component} />
						))}
					</div>
					<div className="row project text-center portfolioCTA">
						<div className="col-12">
							<h2>Need more examples?</h2>
							<h3>Let's Talk</h3>
							<p>
								I have been a software engineer for over 18 years so I can't
								list everything I have done, but I can talk with you about any
								of my previous experience. I am always looking for new
								challenges and opportunities to grow. I would love to hear from
								you.
							</p>
							<br />
							<p>
								<a href="#contact" className="btn draw-border">
									Reach Out
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
