import React from "react";
import preview from "../../../../assets/portfolio/diberville/preview.png";

export default function Diberville() {
	return (
		<div className="row project">
			<div className="col-5" data-aos="fade-up">
				<h2>City of D'Iberville</h2>
				<h3>WordPress Website for Marketing</h3>
				<p>
					The City of D'Iberville needed a new website to help market the city
					to potential residents and businesses. I built a WordPress website
					that helped them accomplish this goal and increase their online
					presence. With the usage of their website, the city was able to
					increase its online traffic by 250% and its city meeting
					attendance by 120%.
				</p>
				<p>
					<em>
						Skills Used: PHP, JavaScript, HTML, CSS, WordPress, Translation API
					</em>
				</p>
			</div>
			<div className="col-7 image">
				<img
					src={preview}
					alt="WordPress Website for City of D'Iberville"
					data-aos="fade-up"
					data-aos-delay="300"
				/>
			</div>
		</div>
	);
}
