import React, { useState, useEffect } from "react";

function Sidebar() {
	const [activeSection, setActiveSection] = useState("home");
	const [popoverState, setPopoverState] = useState({});

	const handleClick = (id) => {
		setActiveSection(id);
		const section = document.querySelector(id);

		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		} else {
			console.error(`Section with ID '${id}' not found.`);
		}
	};

	const handlePopover = (text, id) => {
		const newPopoverState = {};
		newPopoverState[id] = true;
		setPopoverState(newPopoverState);
	};

	const handlePopoverLeave = (id) => {
		const updatedPopoverState = { ...popoverState };
		delete updatedPopoverState[id];
		setPopoverState(updatedPopoverState);
	};

	useEffect(() => {
		const handleScroll = () => {
			const sections = document.querySelectorAll("section[id]");
			const scrollPosition = window.scrollY + 100;

			sections.forEach((section) => {
				const { id } = section;
				const element = document.getElementById(id);

				if (
					element.offsetTop <= scrollPosition &&
					element.offsetTop + element.offsetHeight > scrollPosition
				) {
					setActiveSection(id);
				}
			});
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<aside className="sidebar">
			<ul>
				<li
					className={activeSection === "home" ? "active" : ""}
					onMouseEnter={() => handlePopover("Home", "home")}
					onMouseLeave={() => handlePopoverLeave("home")}>
					<a href="#home" onClick={() => handleClick("#home")}>
						<i className="bi bi-house"></i>
					</a>
					{popoverState["home"] && <span className="popover left">Home</span>}
				</li>
				<li
					className={activeSection === "about" ? "active" : ""}
					onMouseEnter={() => handlePopover("About", "about")}
					onMouseLeave={() => handlePopoverLeave("about")}>
					<a href="#about" onClick={() => handleClick("#about")}>
						<i className="bi bi-info-circle"></i>
					</a>
					{popoverState["about"] && (
						<span className="popover left">About Me</span>
					)}
				</li>
				<li
					className={activeSection === "portfolio" ? "active" : ""}
					onMouseEnter={() => handlePopover("Portfolio", "portfolio")}
					onMouseLeave={() => handlePopoverLeave("portfolio")}>
					<a href="#portfolio" onClick={() => handleClick("#portfolio")}>
						<i className="bi bi-fingerprint"></i>
					</a>
					{popoverState["portfolio"] && (
						<span className="popover left">My Portfolio</span>
					)}
				</li>
				<li
					className={activeSection === "resume" ? "active" : ""}
					onMouseEnter={() => handlePopover("Resume", "resume")}
					onMouseLeave={() => handlePopoverLeave("resume")}>
					<a href="#resume" onClick={() => handleClick("#resume")}>
						<i className="bi bi-person-workspace"></i>
					</a>
					{popoverState["resume"] && (
						<span className="popover left">Resum&eacute;</span>
					)}
				</li>
				<li
					className={activeSection === "contact" ? "active" : ""}
					onMouseEnter={() => handlePopover("Contact", "contact")}
					onMouseLeave={() => handlePopoverLeave("contact")}>
					<a href="#contact" onClick={() => handleClick("#contact")}>
						<i className="bi bi-chat-square-text"></i>
					</a>
					{popoverState["contact"] && (
						<span className="popover left">Contact Me</span>
					)}
				</li>
				<li>
					<a
						href="https://www.linkedin.com/in/ericmarkoliver/"
						target="_blank"
						rel="noopener noreferrer">
						<i className="bi bi-linkedin orange"></i>
					</a>
				</li>
				<li>
					<a
						href="https://github.com/thedevdrawer"
						target="_blank"
						rel="noopener noreferrer">
						<i className="bi bi-github orange"></i>
					</a>
				</li>
				<li>
					<a
						href="https://www.youtube.com/@DevDrawer"
						target="_blank"
						rel="noopener noreferrer">
						<i className="bi bi-youtube orange"></i>
					</a>
				</li>
			</ul>
		</aside>
	);
}

export default Sidebar;
