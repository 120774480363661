import React from "react";
import preview from "../../../../assets/portfolio/netflix/preview.png";

export default function Netflix() {
	return (
		<div className="row project">
			<div className="col-5" data-aos="fade-up">
				<h2>Netflix, BBC, Hyundai</h2>
				<h3>Employee Incentivization Platform</h3>
				<p>
					I helped create and manage an employee incentivization platform built
					for Netflix, BBC, and Hyundai. The platform was built to help
					employees track their progress and earn rewards for their hard work.
					Due to the usage of this platform, employee retention and productivity
					increased by 30%.
				</p>
				<p>
					<em>Skills Used: PHP, JavaScript, HTML, CSS, AWS, Custom API</em>
				</p>
			</div>
			<div className="col-7 image">
				<img
					src={preview}
					alt="Employee incentive tracking app"
					data-aos="fade-up"
					data-aos-delay="300"
				/>
			</div>
		</div>
	);
}
