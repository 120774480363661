import React, { useState } from "react";

const FORM_ENDPOINT = process.env.REACT_APP_FORM_ENDPOINT;

const Form = ({ token }) => {
	const [submitted, setSubmitted] = useState(false);
	const [error, setError] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const inputs = e.target.elements;
		const formData = {};

		for (let i = 0; i < inputs.length; i++) {
			if (inputs[i].name) {
				formData[inputs[i].name] = inputs[i].value;
			}
		}

		formData["token"] = token;
		try {
			const response = await fetch(FORM_ENDPOINT, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (!response.ok) {
				throw new Error("Form response was not ok");
			}

			const data = await response.json();

			if (data.success) {
				setSubmitted(true);
			} else {
				setError(data.message);
			}
		} catch (error) {
			setError(error.message);
		}
	};

	if (submitted) {
		return (
			<div>
				<h3>Thank you for reaching out!</h3>
				<p>I look forward to speaking with you about your opportunity.</p>
			</div>
		);
	}

	return (
		<form onSubmit={handleSubmit} method="POST">
			<h3>Send Me A Message</h3>
			{error && <p className="error">{error}</p>}
			<p>
				If you like my software engineering abilities and would like to discuss
				a career opportunity, please use the form below to send me a message.
			</p>
			<div className="input-wrapper">
				<input
					type="text"
					id="nameInput"
					className="input-field"
					placeholder=" "
					name="name"
					autoComplete="given-name"
					required
				/>
				<label htmlFor="nameInput" className="input-label">
					Your Name *
				</label>
			</div>
			<div className="input-wrapper">
				<input
					type="text"
					id="companyInput"
					className="input-field"
					placeholder=" "
					name="companyname"
				/>
				<label htmlFor="companyInput" className="input-label">
					Your Company Name
				</label>
			</div>
			<div className="input-wrapper">
				<input
					type="email"
					id="emailInput"
					className="input-field"
					placeholder=" "
					name="email"
					autoComplete="email"
					required
				/>
				<label htmlFor="emailInput" className="input-label">
					Email Address *
				</label>
			</div>
			<div className="input-wrapper">
				<input
					type="text"
					id="jobListingInput"
					className="input-field"
					placeholder=" "
					name="joblisting"
				/>
				<label htmlFor="jobListingInput" className="input-label">
					URL for Job Listing
				</label>
			</div>
			<div className="input-wrapper">
				<textarea
					id="messageInput"
					className="input-field"
					placeholder=" "
					name="message"
					required></textarea>
				<label htmlFor="messageInput" className="input-label">
					How Can I Help You? *
				</label>
			</div>
			<div>
				<button className="btn draw-border" type="submit">
					Send a message
				</button>
				<p>
					<small>
						This site is protected by reCAPTCHA and the Google{" "}
						<a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
						<a href="https://policies.google.com/terms">Terms of Service</a>{" "}
						apply.
					</small>
				</p>
			</div>
		</form>
	);
};

export default Form;
