import React from "react";
import MatrixCanvas from "./MatrixCanvas";

function Footer() {
	return (
		<div>
			<div className="triangle-separator"></div>
			<footer>
				<MatrixCanvas height={200} />
				<div className="inner">
					<div className="container text-center">
						<p>&copy; 2024 Eric Oliver. All rights reserved.</p>
						<div className="icons">
							<a href="tel:228-219-9313">
								<i className="bi bi-telephone-forward"></i>
							</a>
							<a href="mailto:eric@ericoliver.io">
								<i className="bi bi-envelope-at"></i>
							</a>
							<a
								href="https://www.linkedin.com/in/ericmarkoliver/"
								target="_blank"
								rel="noopener noreferrer">
								<i className="bi bi-linkedin"></i>
							</a>
							<a
								href="https://github.com/thedevdrawer"
								target="_blank"
								rel="noopener noreferrer">
								<i className="bi bi-github"></i>
							</a>
							<a
								href="https://www.youtube.com/@DevDrawer"
								target="_blank"
								rel="noopener noreferrer">
								<i className="bi bi-youtube"></i>
							</a>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Footer;
