import React from "react";
import { PieChart } from "react-minimal-pie-chart";

const data = [
	{ title: "Backend", value: 25, color: "#00a2ff", label: "Backend" },
	{ title: "Frontend", value: 25, color: "#0774af" },
	{ title: "Management", value: 20, color: "#ffb11f" },
	{ title: "Marketing", value: 15, color: "#FFC862" },
	{ title: "Server", value: 15, color: "#333" },
];

export default function Skills() {
	return (
		<PieChart
			data={data}
			label={({ dataEntry }) => dataEntry.title}
			labelStyle={(index) => ({
                fill: '#fff',
				fontSize: "4px",
				fontFamily: "sans-serif",
			})}
		/>
	);
}
