import React from "react";

export default function IP() {
	return (
		<div>
			Senior Programmer Analyst | Boyd Gaming | 2006 - 2012
			<br />
			<em>Biloxi, MS (On-Site)</em>
			<br />
			<ul>
				<li>
					Conducted in-depth analysis and engineered innovative software
					solutions tailored to address specific business needs, resulting in
					streamlined processes and improved operational efficiency, leading to
					a 35% reduction in project delivery time.
				</li>

				<li>
					Spearheaded the VB.NET and MSSQL development and maintenance of
					critical software applications and databases, ensuring optimal
					performance and reliability, resulting in a 25% increase in system
					uptime and enhanced user experience.
				</li>

				<li>
					Offered expert technical guidance and mentorship to junior team
					members, fostering their professional growth and development,
					resulting in a more skilled and cohesive team, and a 65% improvement
					in project delivery accuracy.
				</li>

				<li>
					Innovatively created internal applications to enhance player
					development and employee retention strategies, resulting in an
					increase in player engagement and satisfaction, and a 40% reduction in
					employee turnover rates.
				</li>
			</ul>
		</div>
	);
}
