import React from "react";
import Skills from "./resume/Skills";
import Links from "./resume/Links";
import JobList from "./resume/JobList";

export default function Resume() {
	return (
		<div>
			<div className="content">
				<div className="inner">
					<h2>
						My <span>Resum&eacute;</span>.
					</h2>
					<br />
					<br />
					<div className="row">
						<div className="col-6">
							<h3>Eric Oliver</h3>
							<h3 className="h3Thin">Experienced Software Engineer</h3>
							<JobList />
						</div>
						<div className="col-4">
							<Skills />
							<br />
							<h4 className="text-center">Links</h4>
							<Links />
							<br />
							<div
								className="text-center"
								data-aos="fade-up"
								data-aos-delay="600">
								<a
									href={
										process.env.PUBLIC_URL +
										"/Eric Oliver, Software Engineer.pdf"
									}
									className="btn draw-border"
									target="_blank"
									rel="noopener noreferrer">
									Download Resum&eacute;
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
