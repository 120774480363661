import React from "react";

const jobs = [
	{ name: "DI", component: "DI" },
	{ name: "FDG", component: "FDG" },
	{ name: "TFG", component: "TFG" },
	{ name: "IP", component: "IP" },
];

export default function JobList() {
	return (
		<div>
			<br />
			<h4>Work Experience</h4>
			{jobs.map((job) => {
				const Component = require(`../resume/jobs/${job.component}`).default;
				return (
					<div key={job.name}>
						<Component />
						<br />
					</div>
				);
			})}
		</div>
	);
}
