import React from "react";
import preview from "../../../assets/portfolio/scarletpearl/preview.png";

export default function ScarletPearl() {
	return (
		<div className="row project">
			<div className="col-5" data-aos="fade-up">
				<h2>Scarlet Pearl Casino</h2>
				<h3>Multiple Time-limited Microsites</h3>
				<p>
					Scarlet Pearl Casino needed a way to create multiple time-limited
					microsites for their promotions. I built a platform that allowed them
					to create and manage these sites with ease. All sites included a
					third-party booking application to process payments and make online
					reservations.
				</p>
				<p>
					With these microsites, the casino increased it's online bookings by
					80% and event registration by 150%.
				</p>
				<p>
					<em>Skills Used: PHP, JavaScript, HTML, CSS, Payment API</em>
				</p>
			</div>
			<div className="col-7 image">
				<img
					src={preview}
					alt="Scarlet Pearl Casino Microsites"
					data-aos="fade-up"
					data-aos-delay="300"
				/>
			</div>
		</div>
	);
}
