import React from "react";
import preview from "../../../assets/portfolio/palace/preview.png";

export default function Palace() {
	return (
		<div className="row project">
			<div className="col-12">
				<div className="image">
					<img
						src={preview}
						alt="Player point portal for casino"
						data-aos="fade-up"
						data-aos-delay="300"
					/>
				</div>
				<div data-aos="fade-up">
					<h2 className="text-center">Palace Casino &amp; Resort</h2>
					<h3 className="text-center">Player Portal</h3>
					<p>
						The Palace Casino &amp; Resort needed a way to help players track
						their points and rewards. I built a player portal that allowed
						players to track their points and rewards in real time. The portal
						also included a rewards store where players could redeem their
						points for in-casino money and reservations. With the usage of this
						portal, the casino was able to increase their player retention by
						65% and their online bookings by 80%.
					</p>
					<p>
						Once the portal was complete, I built a WordPress website that
						integrated the portal and helped the casino increase its online
						traffic by 200% and its online reservations by 150%.
					</p>
					<p>
						<em>
							Skills Used: PHP, JavaScript, HTML, CSS, WordPress, Custom API
						</em>
					</p>
				</div>
			</div>
		</div>
	);
}
