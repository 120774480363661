import React from "react";
import logo from "../assets/signature.png";

export default function Header() {
	return (
		<header>
			<a href="#home">
				<img
					src={logo}
					className="logo"
					alt="Eric Oliver - Software Engineer"
				/>
			</a>
		</header>
	);
}
