import React from "react";
import preview from "../../../assets/portfolio/crabman/preview.png";

export default function Crabman() {
	return (
		<div className="row project">
			<div className="col-5" data-aos="fade-up">
				<h2>Crabman Scuba</h2>
				<h3>Logo and Branding Design</h3>
				<p>
					Crabman Scuba needed a new logo and branding design to help them stand
					out in the market. I created a new logo and branding design that
					helped them increase their visibility and attract new customers.
				</p>
				<p>
					To go with the new branding, I also created a new website design that
					helped them increase their online presence. With this new branding,
					Crabman Scuba was able to increase its online training bookings by
					100% and was able to create a market for branded material.
				</p>
				<p>
					<em>
						Skills Used: Illustrator, Photoshop, PHP, JavaScript, WordPress
					</em>
				</p>
			</div>
			<div className="col-7 image">
				<img
					src={preview}
					alt="Crabman Scuba Logo and Branding Design"
					data-aos="fade-up"
					data-aos-delay="300"
				/>
			</div>
		</div>
	);
}
