import React from "react";

export default function TFG() {
	return (
		<div>
			Director of Website Development | The Focus Group | 2012 - 2014
			<br />
			<em>Gulfport, MS (On-Site)</em>
			<br />
			<ul>
				<li>
					Directed and motivated a team of five backend/frontend developers to
					consistently deliver high-quality websites that exceeded company
					standards and goals, resulting in a 95% increase in client
					satisfaction ratings and positive feedback.
				</li>
				<li>
					Partnered with cross-functional teams to align website development
					initiatives with company objectives, ensuring timely delivery of
					projects and alignment with strategic business goals, leading to a
					150% increase in employer sales conversion rates.
				</li>
				<li>
					Successfully launched and managed over two hundred WordPress websites
					and marketing campaigns for diverse businesses, driving significant
					online visibility and revenue growth, resulting in a 75% increase in
					website traffic and customer engagement for clients.
				</li>
			</ul>
		</div>
	);
}
