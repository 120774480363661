import React, { useEffect } from "react";
import Home from "./sections/Home";
import About from "./sections/About";
import Portfolio from "./sections/Portfolio";
import Resume from "./sections/Resume";
import Contact from "./sections/Contact";

export default function Main({ token }) {
	// Smooth scroll to target section
	useEffect(() => {
		function smoothScrollToTarget(e) {
			e.preventDefault();
			const href = e.target.getAttribute("href");
			if (href && href.startsWith("#")) {
				const targetId = href.slice(1);
				const target = document.getElementById(targetId);
				if (target) {
					const offsetTop = target.offsetTop;
					window.scroll({
						top: offsetTop,
						behavior: "smooth",
					});
				}
			}
		}

		const links = document.querySelectorAll('a[href^="#"]');
		links.forEach((link) => {
			link.addEventListener("click", smoothScrollToTarget);
		});

		return () => {
			links.forEach((link) => {
				link.removeEventListener("click", smoothScrollToTarget);
			});
		};
	}, []);

	// Render
	return (
		<main>
			<section id="home">
				<Home />
			</section>
			<section id="about">
				<About />
			</section>
			<section id="portfolio">
				<Portfolio />
			</section>
			<section id="resume">
				<Resume />
			</section>
			<section id="contact">
				<Contact token={token} />
			</section>
		</main>
	);
}
