import React from "react";

export default function FDG() {
	return (
		<div>
			Director of Software Engineering | Future Design Group | 2014 - 2021
			<br />
			<em>Biloxi, MS (Remote)</em>
			<br />
			<ul>
				<li>
					Orchestrated the successful delivery of software solutions for
					renowned clients such as Netflix, BBC, Hyundai, and the NFL using PHP
					resulting in enhanced brand reputation and increased client
					satisfaction.
				</li>
				<li>
					Identified and resolved intricate technical challenges, ensuring
					seamless software functionality and adherence to industry best
					practices, leading to an 85% reduction in system downtime and improved
					overall system reliability.
				</li>
				<li>
					Engineered and deployed over three hundred WordPress and vanilla PHP
					websites and applications, driving significant business growth and
					online presence for clients across various industries.
				</li>
				<li>
					Developed and launched mobile applications for iOS and Android
					platforms, leveraging Cordova and native code, resulting in a 40%
					additional user engagement and expanded the client's market reach by
					25%.
				</li>
			</ul>
		</div>
	);
}
