import React from "react";

export default function DI() {
	return (
		<div>
			Senior Software Engineer | Dealer Inspire | 2021 - 2024
			<br />
			<em>Naperville, IL (Remote)</em>
			<br />
			<ul>
				<li>
					Engineer scalable web integrations using PHP, JavaScript, SASS, and
					New Relic (NRQL), resulting in 80% improvement in usage metrics
					compared to the previous integrations.
				</li>
				<li>
					Mentored and coached junior developers in implementing best practices
					in PHP, JavaScript, SASS, and CLI, resulting in a 45% reduction in
					code defects and an increase in overall team productivity.
				</li>
				<li>
					In the absence of our direct manager, led a cross-functional team in
					Agile sprints to deliver six feature-rich applications within project
					timelines, contributing to a 60% increase in customer satisfaction and
					95% customer tracking rates.
				</li>
				<li>
					Developed and maintained WordPress plugins leveraging PHP and MySQL,
					resulting in a 40% reduction in website load times and a 95% coverage
					rate in user engagement across the network of 6,000+ websites.
				</li>
			</ul>
		</div>
	);
}
